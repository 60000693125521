footer {
 padding: 2rem 0;
 text-align: center;
 background-color: $red-main;
 color: $white;
 ul.menu li a {
 	margin: 0 .8rem;
 	padding: .5rem 0 .5rem 0;
 	color: $white;
 	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: color, border-bottom;
	transition-property: color, border-bottom;
	border-bottom: .1rem solid $clear;

	 	&:hover {
			color: $red-dark;
	 	}
 	}

 ul.menu li {
 	&:last-child {border-right: none;}
 	}
}

.footer-nav {
	font-family: "Lato", sans-serif;
	font-size: 1.2rem;
	font-weight: 400;
	/* Small only */
	@media screen and (max-width: 46.875em) {
	font-size: 1rem;
	}
}

.social-media {
	font-size: 1.5rem;
	margin-bottom: .2rem;
	ul.menu li {border: 0;}
}

footer.no-red {
	background-color: $clear !important;
	color: $black-text;
}

