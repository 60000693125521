@import 'settings';
@import 'colors';
 // ACTIVE TAB STYLES ARE STYLED IN _settings.scss
.tabs {
	border-bottom: 1rem solid $red-main !important;
	overflow: hidden;

	li {
		border-left: solid .1rem $white;
		border-right: solid .1rem $white;

		&:first-of-type {border-left: solid .0rem $white;}
		&:last-of-type {border-right: solid .0rem $white;}

			@media screen and (max-width: 640px) {
			border-bottom: .1rem solid $white;
			border-top: .1rem solid $white;

			&:nth-child(-n+3) {border-top: .0rem solid $white;}
			&:nth-child(+n+4) {border-bottom: .0rem solid $white;}
			&:nth-child(3) {border-right: .0rem solid $white;}
			&:nth-child(4) {border-left: .0rem solid $white;}
		}
	} 
}

div#line-page-tabs ul li.tabs-title {
	background-color: $red-dark;
	&:hover {background-color: $red-dark-fifty;}

	& a {
		color: $white;
		&:hover {background-color: $red-dark-fifty;}
	}
}

.tabs-title {
	background-color: $red-dark;
	max-width: 250px;
	text-align: center;
	@media screen and (max-width: 1024px){&{font-size: .5rem !important;}}
}

/* Small only */
@media screen and (max-width: 640px) {.grid-x > .tab-small  { width: 50%; }}
@media screen and (min-width: 641px) {.grid-x > .tab-medium { width: 33%; }}
@media screen and (min-width: 1024px) {.grid-x > .tab-large  { width: 20%; }}

div.line-product-menu:nth-child(5) {
	flex: wrap;
	-webkit-flex: wrap;
	-ms-flex: wrap;
	-moz-flex: wrap;
  }

.line-product-menu {
	a {padding: .5rem;}
}

.line-listing div div ul li{
	background-color: $red-dark !important;

}

div.tabs-content.line-listing {
	background-color: #f7f4eb !important;
}

@media screen and (max-width: 640px) {.tabs-panel {padding: .1rem !important;}}


// ul.tabs li.is-active {
// 	-webkit-box-shadow: 0px 4px 3px 1px rgba(0,0,0,0.75) !important;
// 	-moz-box-shadow: 0px 4px 3px 1px rgba(0,0,0,0.75) !important;
// 	box-shadow: 0px 4px 3px 1px rgba(0,0,0,0.75) !important;
// }

