
 	.tabs {
 		border: none !important;
		//  -webkit-box-shadow: 0px 7px 12px -5px rgba(0,0,0,0.5);
		// -moz-box-shadow: 0px 7px 12px -5px rgba(0,0,0,0.5);
		// box-shadow: 0px 7px 12px -5px rgba(0,0,0,0.5);
	
		li.is-active {
			background-color: $red-main !important;
			// border-right: solid .1rem rgba(0,0,0,.7);
 			// border-left: solid .1rem rgba(0,0,0,.7);

 			// &:first-of-type {border-left: solid 0px rgba(0,0,0,0);}
			// &:last-of-type {border-right: solid 0px rgba(0,0,0,0);}

		}
	
 		li.is-active a {
 			background-color: rgba(0,0,0,0) !important;
 		}
		
		li.is-active a:focus {
			outline-width: 0px;
		}

 		.tabs-title {
 			background-color: rgba(0,0,0,0);
			border: 0;
			max-width: 100% !important;

			li a[aria-selected="true"], li a:hover {
				background-color: rgba(0,0,0,0)  ;
			} 


			img {
				max-width: 70px;
			}
 		}
 	}




.oop-price {color: $red-atlas;}

.product-page-container {
	padding-top: 2.5rem;

 @media screen and (max-width: 640px) {
	padding-top: 1.5rem;
}

}

.product-navigation {

	ul {
			
			display: flex;
			border-radius: 3px;
			max-width: 920px;
			margin: 0 auto;

		li {
			padding: 5px 5px 10px;
			margin: 0px;
			background-color: rgba(0,0,0,0) !important;
			@media screen and (max-width: 640px) {
				padding: 0px 0px 10px;
			}
		.tab-active > :first-child {display: none; visibility: hidden;}
		&.is-active a div.tab-active > :last-child {display: none !important; visibility: hidden;}
		&.is-active a div.tab-active > :first-child {display: inline-block !important; visibility: visible;}
		

		}

		li:hover {
			cursor: pointer;

			a img {-ms-transform: scale(1.2) translateY(-2px);
				-webkit-transform: scale(1.2) translateY(-2px);
				transform: scale(1.2) translateY(-2px);}
		}

		li a {
			text-shadow: none;
			font-size: 1.2rem;
			font-weight: bold;
			font-family: 'alike', 'lato', sans-serif;
			background-color: rgba(0,0,0,0) !important;

			img {transition: transform .3s, -webkit-transform .3s, -ms-transform .3s;}


			&:hover img {
				-ms-transform: scale(1.2) translateY(-2px);
				-webkit-transform: scale(1.2) translateY(-2px);
				transform: scale(1.2) translateY(-2px);
			}

			@media screen and (max-width: 640px) {
				img {height: 60px;}
				font-size: 1rem;
			}
		}
	}
}