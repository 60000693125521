@import 'settings';
@import 'colors';

nav {
    z-index: 998;
	min-height: 0;
	color: rgba(255, 255, 255, .9);

}

.main-header-container {
	padding-top: 100px;
}

nav ul.menu a {
	padding: 0rem;
}

nav ul.menu li.social-media a {padding: 0rem .5rem;}

 .secondary-nav {
 	background-color: $red-dark;
 	padding: 0 20px;
 	border: .5rem solid transparent;
	
 	.cart{
 		font-size: 1.5rem;
 		&::before {
 			content: "\f07a";
 			font-family: FontAwesome;
 			}
 	}

 		li a {font-size: 1.5rem;}
 }

nav a {
	font-size: .9rem;
	color: $white;
}

.nav-scrolled {
	padding-top: 56px;
}

.main-nav {
width: 100%;
height: 85px;
background-color: $red-main;
color: $white;
font-family: 'Lato', sans-serif; 
font-weight: 600;
position: absolute;
	//text-transform: uppercase;

	@media screen and (max-width: 750px) {
		height: 70px;
	}

}

.main-nav-scrolled {
  position: fixed; 
  width: 100%;
  top: 0;
  height: 70px;
  border-bottom: solid .09rem $red-dark-fifty;
  @media screen and (min-width: 750px) {
		-webkit-animation-name: nav-shrink;
	  	-webkit-animation-duration: .3s;
	  	animation-name: nav-shrink;
		animation-duration: .3s;
	  }
	#main-search-bar {
		top: 70px;
		transition: top .3s;
		z-index: 100;
	}


  	.nav-navbar {
  		height: 70px;
  		z-index: 998;

  		@media screen and (min-width: 750px) {
			-webkit-animation-name: nav-shrink;
		  	-webkit-animation-duration: .3s;
		  	animation-name: nav-shrink;
			animation-duration: .3s;
	  	}
  	}
  	.logo {
  		max-width: 140px;
  		position: absolute;
  		z-index: 999;
		
		a img {
		-webkit-animation-name: logo-shrink;
		-webkit-animation-duration: 1s;
		animation-name: logo-shrink;
		animation-duration: 1s;
			/* Small only */
			@media screen and (max-width: 750px) { 
				-webkit-animation-name: logo-stop;
				-webkit-animation-duration: 0s;
				animation-name: logo-stop;
				animation-duration: 0s;
			}
		}
  	}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes nav-shrink {
    0%   {height: 85px;}
    100% {height: 70px;}
}

/* Standard syntax */
@keyframes nav-shrink {
    0%   {height: 85px;}
    100% {height: 70px;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes logo-shrink {
    0%   {min-width: 140px;}
    100% {min-width: 100px;}
}

/* Standard syntax */
@keyframes logo-shrink {
    0%   {min-width: 140px;}
    100% {min-width: 100px;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes logo-stop {
    0%   {min-width: 100px;}
    100% {min-width: 100px;}
}

/* Standard syntax */
@keyframes logo-stop {
    0%   {min-width: 100px;}
    100% {min-width: 100px;}
}




.mobile-container{
	// display: none;
	min-width: 100%;
	z-index: 1;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-webkit-justify-content: space-around;
	-moz-justify-content: space-around;
	-ms-justify-content: space-around;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
	-moz-align-items: center;
	background-color: $red-main;

		@media screen and (min-width: 750px) {
		min-width: 0;
	}
}

#mobile-button {
	overflow: hidden;
	cursor: pointer;
	width: 50px;
	height: 50px;
	z-index: 100;
	margin: 10px 15px 10px 0;
	border-radius: 10px;
	border: 2px solid rgba(73,7,16,.2);
	background-color: rgba(132,38,45,.9);
}

.mobile-switch-up {
		width: 100%;
		border-bottom: 3px solid rgba(73,7,16,.7);
		border-top: 3px solid rgba(73,7,16,.7);
		overflow: hidden;
		border-radius: 5px;
		margin-top: -3px;
		background-color: #a82630;
		transition: 0.2s;
	}

.switch-on {
	-webkit-transform:translateY(10px);
	transform:translateY(10px);
}

.mobile-icon {
	background-color: #fff;
	width: 35px;
	height: 4px;
	margin: 6px 5px;
	border-radius: 5px;
	display: block;
}


.nav-navbar {
	position: relative;
	min-width: 100%;
	height: 85px;
	display: none;
	z-index: 100;
	// -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.51);
	// -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.51);
	// box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.51);
	ul li {
		// overflow: hidden;
	}
}

.nav-navbar ul li a {
	text-align: center;
	margin: 0 .75rem;
	@media screen and (max-width: 26.875em) {
		& {margin: 0rem;
		padding: .2rem 0;}
	}
	padding: .2rem 0;
	-webkit-transition-duration: 0.3s;
  	transition-duration: 0.3s;
  	-webkit-transition-property: color, background-color, border;
  	transition-property: color, background-color, border;
  	border: .2rem solid rgba(0, 0, 0, 0);
  	order: 1;

  	.category-icon img {
  		-webkit-transition: size .3s;
  		transition: transform .3s;
  	}
}



.nav-navbar ul li a:hover {
	color: $white;
	.category-icon img {
		-ms-transform: scale(1.2) translateY(-2px);
		-webkit-transform: scale(1.2) translateY(-2px);
		transform: scale(1.2) translateY(-2px);
	}
}


.nav-navbar ul li a:active {
	//border-bottom: .2rem solid $white;
}

.category-icon {
	width: 100%;
	height: 30px;
	text-align: center;
	padding-bottom: 5px;

	img {
		max-width: 100%;
		max-height: 100%;
	}
	// @media screen and (min-width: 750px) { 
	// 	display: none;
	// }
}


button.category-icon{
	padding: 0;
}

.logo {
	max-width: 160px;
	padding-left: 10px;
	position: absolute;
	z-index: 999;

	@media screen and (max-width: 750px) {
		& {
			max-width: 100px !important;
			padding: 3px 0 0 3px;
			// -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0) !important;
			// -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0) !important;
			// box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0) !important;
			
		}
	}

}

#main-search-bar .main-search-bar-flexcontainer input {
		border-radius: 0px;
		min-height: 40px;
		margin: 0;
	}

.main-search-bar-flexcontainer {
	display: -webkit-flex;
	display: -ms-flex;
	display: -moz-flex;
	display: flex;
	min-width: 0px;

}


.search-button {
		max-width: 150px;
		width: 150px;
		min-height: 40px;
		background-color: rgba(73,7,16,.4);
		color: rgba(255,255,255,.9);
		cursor: pointer;
		border: 0px solid rgba(0,0,0,0);

		&:hover {
			border: solid .1rem $red-dark;
			color: $red-main;
			background-color: $red-dark;
		}

		&:active {
			border: $black-text;
			color: $black-text;
			background-color: $red-main;
		}
	}

.search-toggle {
		height: 50px;
		width: 50px;
		margin-right: 10px;
		color: $white;
		cursor: pointer;
		font-size: 1.2em;
		text-align: center;
		border: solid .1rem rgba(73,7,16,.4);;
		border-radius: 5px;

		/* Small only */
		@media screen and (max-width: 749px) { 
		display: none !important;
		}

		&:hover {
			background-color: $red-dark;
			color: $red-main;
			border: solid .05rem rgba(0,0,0,0);
		}

		&:active {
			border: $black-text;
			color: $black-text;
			background-color: $red-main;
		}
	}

#main-search-bar {
	width: 100%;
	background-color: $red-main;
	margin: 0;
	right: 0;
	border-top: 1px solid #5a1f23;
	justify-content: center;
	align-items: center;
	padding: 20px;
	z-index: 100;
}

.search-icon { 
	color: $white;
	font-size: 1.5rem;
	padding: 20px;
 }

li.social-media {
	padding: 0px 40px;
}

 .shopping-cart div {
 	padding: .5rem;
 	margin-right: 3px;
 	border-radius: 5px;
 	display: -webkit-flex;
 	display: -ms-flex;
 	display: -moz-flex;
 	display: flex;
 	-webkit-justify-content: center;
 	-ms-justify-content: center;
 	-moz-justify-content: center;
 	justify-content: center;
 	-webkit-align-items: center;
 	-ms-align-items: center;
 	-moz-align-items: center;
 	align-items: center;
 	background-color: $red-main;

 	&:after {
 		content: "0";
 		font-size: 1.25rem;
 		padding: 0 5px;
 	}
 }


/* Small only */
@media screen and (max-width: 26.875em) { 
	.nav-navbar ul li a{
		min-width: 106px; 
	}
}

/* Medium and up */
@media screen and (min-width: 46.875em) {

	.nav-navbar {
		min-width: 0%;
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flex !important;
		display: -moz-flex !important;

	}

	#mobile-button {
		display: none;
	}

	.mobile-container{
	top: 0px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	-ms-justify-content: flex-end;
	-moz-justify-content: flex-end;
	justify-content: flex-end;
	background-color: rgba(0,0,0,0);
	}

	.nav-navbar ul li a{
		text-align: center;
		-webkit-transition-duration: 0.3s;
	  	transition-duration: 0.3s;
	  	-webkit-transition-property: color, background-color, border;
	  	transition-property: color, background-color, border;
	}

	// .nav-navbar ul li a {
	// 	min-height: 70px;
	// 	max-height: 70px;
	// 	width: 80px;
	// 	padding: .6rem 0 0 0;
	// 	}

	.nav-navbar ul li a, {
		border: 1px solid rgba(0, 0, 0, 0);
	}

	.nav-navbar ul li a:hover, 
	.nav-navbar ul li a:active, 
	.nav-navbar ul li a:active object:last-child,
	.nav-navbar ul li a:active object:first-child {
		color: rgba(255,255,255,.9);
	}

	.category-icon object {
		max-height: 25px;
	}

#main-search-bar {
	width: 540px;
	top: 85px;
	position: absolute;
	display: none;
	z-index: 100;
}

.main-search-bar-flexcontainer {
	display: -webkit-flex;
	display: -ms-flex;
	display: -moz-flex;
	display: flex;
	width: 500px;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	-moz-justify-content: center;
	justify-content: center;

}


#main-search-bar .main-search-bar-flexcontainer input {
	width: 400px;
	border: none !important;

}
}

/*
========================
Small Nav
========================
*/
.small-nav {
	font-size: .8rem;
	background-color: $brown-atlas;
	padding: 0 10px 0 0;
	margin: 0;

	display: -ms-flexbox;
	display: -webkit-flex; 
	display: flex;

	justify-content: flex-end;
	-ms-justify-content: flex-end;
	-webkit-justify-content: flex-end;

	li {
		list-style-type: none;
		padding: 5px 3px;
		color: $brown-dark-atlas;
		
		a {
			color: $brown-background;
		}
	}
}


/*
========================
Breadcrumb Navigation
========================
*/

	ul.breadcrumbs {
		padding: 1rem;
		margin: 0px;

		& li:last-child {
			background-color: $red-main;
			a {
				color: $white;
				padding: 5px 10px;
			}
		}


		li {
			background-color: $white;
			display: inline-block;
			transform: skew(-22.5deg);
			-webkit-transform: skew(-22.5deg);
			
			border: solid .1rem $red-main;
			font-size: $global-font-size * 1.2;
			border-right: solid .0rem $red-main; 
		}

		a {
			display: block;
			padding: 5px 10px;
			color: $red-main;
			font-size: $global-font-size * .5;
			/* Small only */
			@media screen and (max-width: 640px) {font-size: $global-font-size * .6;}
			transform: skew(22.5deg);
			-webkit-transform: skew(22.5deg);
			
		}
	}

.pagination {
	text-align: center;}



