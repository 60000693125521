#discountcode {background-color: $white;}

.discountcode {background-color: $brown-background-dark !important;}


.cart {
	margin-bottom: 20px;;
}

.cart-items {
	padding: 10px 10px 10px 10px;
	@media screen and (max-width: 640px) {
		padding: 0px;
	}
}

#cart-container .cart-items:nth-child(odd) {
	background-color: $brown-background;
}

div.grid-x.cart-head {
	font-size: 1.2rem;
	background-color: $brown-dark-atlas;
	text-align: center !important;

	& div b {
	color: $white !important;
	}

	@media screen and (max-width: 640px) {
		display: none !important;
		visibility: hidden !important;
	}
}

div.grid-x.cart-head-mobile {

	display: none;
	visibility: hidden;

	@media screen and (max-width: 640px) {
	color: $brown-atlas;
	text-align: center;
	border-bottom: 2px solid $background-darker;
	margin-bottom: 5px;
	display: flex ;
	visibility: visible;
	}

}

.cart-image {padding: 10px;}

.login-require-cart {
	border: solid 0.3rem $red-atlas;
	padding: 20px !important;
	color: $red-atlas;
}

.cart-head-checkout {
	font-size: 1.2rem;
	background-color: $red-main;
	text-align: center !important;
	color: $white !important;
}

.cart-item-info div {
	text-align: center;
	border-left: solid 2px rgba(0,0,0,.2);
	height: 100%;

	@media screen and (max-width: 640px) {

	border: solid 0px rgba(0,0,0,.0) !important;

	input {
			margin-bottom: 5px !important;
		}
	}
}

.checkout {		
	min-width: 200px;
	padding: 20px 0;
    border: none;
    color: $white !important;
    background-color: $brown-atlas;
    padding: .8rem;
    margin: .1rem;
    font-size: 1rem;	
    text-align: center;
	cursor: pointer;
	-webkit-transition: transform .3s, color .3s; /* Safari */
   	 transition: transform .3s, background-color .3s;		

		&:hover {
			-ms-transform: scale(1.05); /* IE 9 */
		    -webkit-transform: scale(1.05); /* Chrome, Safari, Opera */
		    transform: scale(1.05);
		    background-color: $brown-dark-atlas;
				}	
	
}

.checkout-cart {
	 th {background-color: $red-main;}
}

.cart-totals > div{
	background-color: $brown-background-dark;
	padding: 10px;

	.total {padding-bottom: 5px;}

}

.remove-from-cart {
	color: $brown-atlas;
	-webkit-transition: color .3s, background-color .3s; /* Safari */
   	transition: color .3s, background-color .3s;	
	cursor: pointer;
	display: block;
	margin: 0 auto;
	background-color: $background-dark;
	padding: 7px;
	margin: 0  10px 0 0;

	&:hover {
		color: $brown-dark-atlas;
		background-color: $background-darker;
	}

	@media screen and (max-width: 640px) {
		background-color: $clear;
		font-size: .8rem;
		padding-left: 0px;

	&:hover {
		color: $brown-dark-atlas;
		background-color: $clear;
		}
	}
}

.update-cart {
	color: $brown-atlas;
	-webkit-transition: color .3s, background-color .3s; /* Safari */
   	transition: color .3s, background-color .3s;
	border: none;
	padding: 5px;
	background-color: $background-dark;

	&:hover {
		color: $brown-dark-atlas;
		background-color: $background-darker;
	}
	
	@media screen and (max-width: 640px) {
		background-color: $clear;
		font-size: .8rem;
	}

}

.confirm-address {
	color: $red-atlas;
	padding: 10px;
	border: solid .3rem $red-atlas;
	font-weight: 700;
	width: 100%;
	text-align: center;
}

.confirmed-address {
	color: $blue-focus;
	padding: 10px;
	border: solid .3rem $blue-focus;
	font-weight: 700;
	width: 100%;
	text-align: center;
}

div.grand.total {
	font-size: 24px !important;
}

.checkout-address-container {

p {
	line-height: 1.2 !important;
	font-size: .9rem;
  padding: 0px 0px 0px 0px;
  }
 label input[type="radio"] {
    margin: 15px;
  }
  
}

.checkout-bg {
	margin: 0px !important;
	padding: 0px !important;

}
.checkout-nav {
	height: 100%;
	width: 100%;
	ul {height: 100%;}
}

.checkout-address-container {
	input[type="radio"]{
		margin: 10px !important;
	}
}