.footer-search {
	width: 100%;
	padding: 0 15px 0 15px;
input {
	border-radius: 0;
	height: 55px;
}
}

.home-search {
	background-color: $red-main;
	overflow: hidden;
	margin-top: 85px;
	position: absolute;


	form{

		display: -webkit-flex;
		display: flex;

		input[type="search"] {
			height: 55px;
			padding: 0px;
			margin: 0px;
			padding: 0rem .5rem;
			font-size: 1rem;
			color: $black-text;
		}
		input[type="submit"] {
			border: solid 0px rgba(0,0,0,0);
			font-family: FontAwesome;
			font-size: 1.5rem;

			&:active {background-color: black;}
		}
	}
	@media screen and (max-width: 749px) {
		width: 100%;
		margin: -350px 0 0 0;
	}

	@media screen and (max-width: 640px) {
		width: 100%;
		margin: -95px 0 0 0;
	}
}