form, .form-class {
  h3 {
    font-weight: 700;
    // border-bottom: 0.08rem solid rgba(0, 0, 0, 0.5);
  }
}

input {
  margin: 0 0 20px 0 !important;
}

fieldset {border: solid 0px $medium-gray !important;}

.accordion-custom-title a {
	background-color: $brown-atlas;
	color: $white;

	&:focus, &:hover {
		background-color: $brown-dark-atlas;
		color: $white;
	}
}

.accordion-custom-title.is-active a {
	background-color: $brown-dark-atlas !important;
}

.error {

	.my-form-error {
		color: $red-atlas;
	}

	input, textarea {
		border: solid .2rem $red-atlas;
		margin: 0;
	}
}

.submit-button {
	background-color: $blue-link;
	border: none;
	font-size: 1.2rem;
	font-weight: 700;
	padding: 10px 10px;
	text-align: center;
	max-width: 220px;
	-webkit-transition: transform .1s, color .1s; /* Safari */
    transition: transform .1s, background-color .1s;
    color: $white;
	
	&:hover {
		-ms-transform: scale(1.05); /* IE 9 */
    	-webkit-transform: scale(1.05); /* Chrome, Safari, Opera */
    	transform: scale(1.05);
    	background-color: $blue-dark;
		}
}



.new-account {
  border-top: .1rem solid #cacaca;
  margin-top: 20px;
  font-size: 125%;
  padding: 40px 0;
}


// Custom Checkboxes

.form-upload {
  padding: 0 20px 0 20px;
  margin-bottom: 20px;
  input {margin: 0 auto;}

  input[type="file"] {}
}

 /* Customize the label (the check-container) */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border: 1px solid $medium-gray;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: $white;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: $blue-link;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} 

.check-label {
	margin-right: 25px;

	@media screen and (max-width: 640px) {
		font-size: 18px;
	}
}

.shipping-checkbox {
  background-color: $brown-background;
  padding: 20px;

  #shipping-checkbox {
    margin: 0; 
    padding: 0;
  }
}

input[type="number"] {width: 50px !important;}

.form-border {
  border-bottom: .05rem solid $background-dark;
  content: "";
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.total {text-align: right;}


.checkout-address {
}

@media screen and (max-width: 640px) {
	div.form-checkboxes.flex-container {
		display: block !important;
	}
}


/*
=============================================
SPECIAL OPS
=============================================
*/

.red-container {

  .form-section {
    padding-bottom: 40px;
  }

  form ul {
  margin: 0;

  li {
    list-style-position: inside;
    list-style-type: none;
  }
  }

  fieldset {
    border: solid .2rem $red-dark-fifty !important;
    border-radius: 20px;
    padding: 30px;

    @media screen and (max-width: 640px) {
      border: solid .0rem $red-dark-fifty !important;
      border-radius: 0px;
      padding: 0px;
    }

  }

  label {
    color: $white;
  }

  input{
    background-color: $white-input !important;
  }

}
