// Newsletter signup styles

.newsletter {
	color:$white;
	-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.60);
	-moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.60);
	box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.60);

	h2 {border: none;}

	label, a {color: $white;}

	.menu a {
		padding-right: 50px;
	}

	.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {border-color:$white transparent;}

	.accordion-menu a {padding: 0;}

	ul {
		background-color: rgba(0,0,0,0) !important;
		margin-left: 0.89286rem;
		margin-right: 0.89286rem;
		background-color: $red-main !important;
	}
	
	li {
		display: block;
		margin: 0 !important;
		border-bottom: solid .2rem $red-dark-fifty;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		-moz-box-align-items: center;
		-ms-align-items: center;
		-webkit-box-align-items: center;
		align-items: center;
		flex-wrap: wrap;

	&:last-of-type {border-bottom: none;}
		input[type="checkbox"] {
			display: inline-block;
			margin: 0 !important;
			width: 5%;
		}
		label {
			display: inline-block;
			padding: 5px;
		}
	}

}

ul.navbar-submenu {
	background-color: $red-dark !important;
	margin: 0px !important;
	padding-left: 0.89286rem;
	width: 100%;
}

.heading-underline {
	border-bottom: 2px solid $white;
	padding-bottom: 10px;
}

.newsletter-bg {
	padding: 3rem 0;
	background-image: url("../img/newsletter-banner.png");
	background-attachment: fixed;
    background-repeat: repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 640px) {
			& {
				padding: 2rem .6rem;
				background-position: center;
			}
		}
}

.newsletter{
label {
font-size: .9rem;
// font-style: italic;
font-weight: 700;
}}

legend {
	font-size: 1.5rem;
	font-weight: 700;
}

.newsletter {

		input {
	background-color: rgba(255,255,255,.9);
	color: rgba(0,0,0,.9);
	}

	form ul {

		list-style-type: none;

		
		.newsletter-checkbox li { 
		overflow: hidden;
		width: 100%;
	 	}

	 	a:hover {color: $white;}
	 	a:active {color: $white;}
	}
}

.newsletter-signup {

	label {
	font-size: 1.2rem;
	}
}

.newsletter-form-container {
	padding: 20px;
}

input.newsletter-submit {
	color: $white;
	width: 200px;
	padding: 15px 0px;
	background-color: $blue-link;
	border: 0px solid rgba(0,0,0,0);
	-webkit-transition: transform .3s, color .3s; /* Safari */
   	 transition: transform .3s, background-color .3s;		
	&:hover {
			-ms-transform: scale(1.05); /* IE 9 */
		    -webkit-transform: scale(1.05); /* Chrome, Safari, Opera */
		    transform: scale(1.05);
		    background-color: $blue-dark;
				}	
}


fieldset.newsletter-fieldset {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#999999+0,000000+100&0+0,0.35+100 */
	background: -moz-linear-gradient(top, rgba(153,153,153,0) 0%, rgba(0,0,0,0.35) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(153,153,153,0) 0%,rgba(0,0,0,0.35) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(153,153,153,0) 0%,rgba(0,0,0,0.35) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00999999', endColorstr='#59000000',GradientType=0 ); /* IE6-9 */
	padding: 1.25rem 0 0 1.25rem;
}
 
.newsletter-fieldset {
	border: 2px solid rgba(255,255,255,.9);

}


.newsletter {
	a.accordion-title {
		background-color: rgba(0,0,0,0) !important;
		border: none;
		padding: 0;
		width: 95%;
		height: 100%;


		&::before {
			padding-top: 3px;
		}	

	}
}
