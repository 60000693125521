$red-main: #a82630;
$red-atlas: #ba131a;
$red-dark-fifty: rgba(73,7,16,.5);
$red-dark: rgba(73,7,16,1);

$clear: rgba(0,0,0,0);

$blue-link: #4c7da9;
$blue-focus: #6caae0;
$blue-dark: #1a4770;

$brown-atlas: #7D5019;
$brown-dark-atlas: #533715;
$brown-background:  #f7f4eb;
$brown-background-dark: #eae6d9;

$background-light: rgba(0,0,0,.03);
$background-dark: rgba(0,0,0,.1);
$background-darker: rgba(0,0,0,.2);

$white: rgba(255,255,255,.95);
$white-input: rgba(255,255,255,.85);
$white-hover: rgba(255,255,255,.70);

$black-text: rgba(0,0,0,.80);