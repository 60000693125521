.news-item {
	border-bottom: .1rem solid $brown-background;	
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 20px;

	img {
		padding-bottom: 20px;
	}
}

.entry-title:hover, .entry-summary:hover {
	cursor: pointer !important;	
}

.author {
	padding-bottom: 15px;
	text-align: left;
}

.blogpost {
	padding-bottom: 15px;
}

article {
	h1 {margin:0;}
	hr {margin: 0px 0px 15px 0px;}
}
.social-sharing {

ul { 
	padding: 0;
	display: flex;
	margin-left: 0;

}

 li {
 	list-style-type: none;
	list-style-position: inside;
	overflow: hidden;
	border-radius: 100px;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;


	a {
		width: 40px;
		height: 40px;
		padding: 5px;
		color: $white;
		font-size: 1.2rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


li:first-child {
	background-color: #3B5998;

}

li:nth-child(2) {
	background-color: #00aced;

}

li:nth-child(3) {
	background-color:  #dd4b39;

}
}

.categories-sidebar ul {
	margin: 0;
	padding: 0 .8rem 1.2rem .8rem;
}

.categories-sidebar ul li, .categories {
	list-style-type: none;
	display: inline-block;
	font-size: .9rem;
	text-align: center;
	margin: .0rem;
	padding: 0px;
	color: $brown-background;
	overflow: hidden;

	a {
		color: $brown-atlas;
		padding: 5px;
		background-color: rgba(125, 80, 25, .2);

		&:hover {
			text-decoration: underline;
		}
	}
}

.categories {
		color: $brown-atlas;
		padding: 5px;
		background-color: rgba(125, 80, 25, .2);

		&:hover {
			text-decoration: underline;
		}
}

.tags a {
}

.dates-months-sidebar ul {
	padding:0;
	text-align: center;
}
.dates-months-sidebar ul li {
	list-style-type: none;
	list-style-position: inside;
	display: inline-block;
	text-align: center;
	color: $brown-background;
	overflow: hidden;
	color: $brown-atlas;
	

	a {
		color: $brown-atlas;
		width: 100%;
		padding: 10px;
		&:hover {
			border: 0px solid $clear !important;
			background-color: rgba(125, 80, 25, .2);
			color: $brown-atlas;
		}
	}
}

.accordion-title {background-color: $brown-background;}
.accordion-item.is-active {background-color: rgba(125, 80, 25, .2);}

 .dates-months-sidebar ul li ul.accordion-content {
		margin-right: 1.25rem;

	li {
	 	border: solid .0rem $clear !important;
	 	width: 100%;
	 	text-align: left;
		padding: 5px;
		line-height: 1.2 !important;
		margin: 0 auto;
		border-bottom: solid .1rem rgba(125, 80, 25, .1) !important;
		&:last-of-type {border-bottom: solid .1rem rgba(125, 80, 25, .0) !important;}
	}

 	a {
 		padding: 0px !important;
		margin: 0px auto !important;
		width: 100%;
		text-align: left;
		font-size: .9rem;

		&:active {background-color: rgba(125, 80, 25, .0) !important;}
		&:focus {background-color: rgba(125, 80, 25, .0) !important;}

 	}
 }

ul.dates-categories li {
	background-color: $brown-background;
	border: solid .05rem $brown-atlas;
	width: 100%;

	a {
		color: $brown-atlas;
		width: 100% !important;
		display: inline;
	}

}

.archive-sidebar {
	width: 100%;
}


ul.archive-sidebar li a{
 	border: 0px solid $clear !important;
 	font-size: 1rem;
 	padding: 7px 30px 7px 15px;
	 &:hover, &:active {
		border: 0px solid $clear !important;
		color: $brown-atlas;
		background-color: $clear; 
		text-decoration: underline;
	}

	&:focus {
		background-color: $brown-background;
		color: $brown-atlas;
	}
}

ul.archive-sidebar {
	background-color: $clear;

	li ul {
		background: $clear;
		border: 0px solid $clear !important;
		padding-top: 5px;
		padding-bottom:5px;
	}
}

.description a {
	color: $black-text;
	&:hover {
		color: $black-text;
	}
}

.entry-title {
	color: $black-text;
	&:hover{
		color: $blue-link;
	}
}