.loggedinuser {
	padding: 0 20px 20px;
	margin-bottom: 20px;

}

.address-confirmation {
	background-color: $brown-background;
	padding: 30px;
}

.address-container {
	border: .1rem solid $background-light;
	border-radius: 5px;
	background-color: $background-light;
	padding: 15px;
	margin: 10px;
}