// small: 0,
// medium: 640px,
// large: 1024px,
// xlarge: 1200px,
// xxlarge: 1440px,


// Universal Styles

@import 'settings';
@import 'colors';

html body {
  padding: 0;
  margin: 0;
  color: $black-text;
  background-color: $white;

  p {
  font-size: 1rem;
	}
}

html {height: 100%;}
body {min-height: 100%;}

a.super-special-link {
	display: block;
	text-align: center;
	max-width: 400px;
	border: solid .02rem $blue-link;
	font-size: 1.2rem;
	padding: 10px;
	color: $blue-link;
	text-decoration: none;
	margin: 0 auto;
	transition: background-color .5s, color .5s;

	&:hover {
	background-color: $blue-focus;
	color: $blue-dark;

	}
}


body {
  font-family: "lato", sans-serif;
}

main {
	position: relative;}

header {
	overflow: hidden;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);

	.atlas-two-column, .atlas-one-column {
		padding-top: 0;
		padding-bottom: 0;
	}

    .header-content {
		max-height: 520px;
		@media screen and (max-width: 640px) {
			max-height: 350px !important;

		}
		overflow: hidden;

		& > div {
		padding-top: 85px;
		@media screen and (max-width: 640px) {
			height: 100%;
			padding-top: 0;
		}
		}

		img {
			max-width: 600px;
			@media screen and (max-width: 640px) {
				max-width: 300px;
				padding-top: 90px
			}
		}
    }

    .header-image {
    	max-height: 400px;
    	overflow: hidden;

    	img {max-height: 300px;}
    	@media screen and (max-width: 640px) {
			display: none;
		}
    }

    .grid-container {
		height: 100%;
    }

    .product-page-bg {
    	max-width: 2000px;
    	min-width: 2000px;
    }

     .product-page-title {
    	padding: 10px 10px 10px 10px;
    	color: $white;
    }

    .page-title {
    	padding: 0;
    	color: $white;
    	text-align: center;
    	position: absolute;
    	height: 235px;
    	width:100%;

    	@media screen and (max-width: 640px) {
    		height: 130px;
    		}
    }

	h1 {
		padding: 0;
		margin: 0;
		line-height: 1;
	}

	.game-info {
		 font-size: 20px;
		 margin-right: 20px;
		 	@media screen and (max-width: 640px) {
				& {
					font-size: 16px;
					margin-right: 10px;
				}
			}
		}
}

.form-padding {
	padding-bottom: 40px;
}

header.line-page-header {
	text-shadow: 0px 0px 0px rgba(0, 0, 0, 0)!important;
	padding-bottom: 40px;
	@media screen and (max-width: 640px) {
			& {padding: 0px .8rem 40px .8rem;}
		}
}


	.title-callout {
		font-size: 1.3rem;
		font-style: italic;
		font-weight: 700;
	@media screen and (max-width: 640px) {
			& {font-size: 1rem;}
		}
	}

section {
	// padding-bottom: 7rem;

 	h2 {
	  border-bottom: .08rem solid rgba(0,0,0,.50);
	  margin-bottom: 1.5rem;
	  font-family: 'Alike', serif;
	  font-size: rem-calc(28);

	  	@media screen and (max-width: 640px) {
			font-size: rem-calc(24);
		}

		}
}

// Adds padding to sides on mobile
	@media screen and (max-width: 640px) {
			section, article {padding: 0 .8rem;}
		}

article {

	img {
		padding-bottom: 20px;
		text-align: center;
		display: block;
		margin: 0 auto;
	}
	h1 {
		line-height: 1.1;
		padding-bottom: .5rem;
	}
	h2 {
		border-bottom: none;
		margin: 0;
		font-weight: 700;
	}
	h3 {margin: 0;
		font-weight: 700;}
 	h4 {margin: 0;}

 	blockquote {
 		font-size: 1.2rem;
 		margin: 0 0 2rem 1rem;
 		// background-color: $brown-background;
 	// 	-webkit-box-shadow: 0px 0px 0px 15px rgba(250,248,242,1);
		// -moz-box-shadow: 0px 0px 0px 15px rgba(250,248,242,1);
		// box-shadow: 0px 0px 0px 15px rgba(250,248,242,1);
 	}

 	ul, ol {
 		padding-left: 1rem;
 	}

 	ul li {
 		padding-bottom: 10px;
 	}
}

.atlas-two-column, .atlas-one-column, .atlas-sidebar-layout, .red-container {
	min-height: 100%;
	padding: 0rem 1.25rem 3rem 1.25rem;

	header {margin: 0rem 0rem -2rem 0rem;}


  /* Small only */
	@media screen and (max-width: 640px) {
	padding: 0 0 2rem 0;
	min-height: initial;
	}

	/* Medium only */

}

.atlas-column {
	min-height: 100%;
	padding: 2rem 1.25rem 2rem 1.25rem;

  /* Small only */
	@media screen and (max-width: 640px) {
	padding: 2rem 0 2rem 0;
	min-height: initial;
	}
}

.atlas-one-column {
	margin: 0 auto;
}

.atlas-second-column {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.floating-container {
	overflow: hiddden;
	background-color: white;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	z-index: 100;
}

.no-breadcrumb {
	padding-top: 30px !important;
}
.header-container, .home {
	margin-bottom: -80px;
	width: 100%;
	z-index: -100;
	padding-top: 85px;
	overflow: hidden !important;

	/* Small only */
	@media screen and (max-width: 640px) {
	max-height: 310px;
	padding-top: 70px;
	margin-bottom: 0px;
	}
}

.header-container {
	overflow: hidden;

	}

.homepage-header {
	z-index: -111;
	overflow: hidden;
	// position: fixed;

	div {
	z-index: -111;
	//position: fixed;
	overflow: hidden;
	width: 100%;
	height: 600px;

	img {
		min-width: 2000px;
		margin: 0 auto;
		z-index: -112;
	}

	}

	  @media screen and (max-width: 1200px) {
		display: none;
		visibility: hidden;

	}
}

.homepage-header-small {
	z-index: -112;
	 overflow: hidden;
	 width: 100%;
	 min-height: 600px;
	// max-width: 1200px;
	// float: left;
	//position: fixed;

		div {
	 overflow: hidden;
	// //position: fixed;
		z-index: -112;
	min-height: 600px;
	display: block;


	img {
		// min-width: 1200px;
			z-index: -112;
			display: block;
			min-height: 600px;
			min-width: 1200px;

		}
	}
}

.about-feature {

	color: $white;

	h3 {
		font-size: 1.5rem !important;
		padding: 0px;
		margin: 0px;
	}

	& img {
		padding-bottom: 10px;
		transition: transform .5s;

	}

	&:hover img {
		transform: scale(1.05);
		-webkit-transform: scale(1.05);
		-ms-transform: scale(1.05);
	}

img {
	max-width: 300px;
	width:100%;
	}
}

.staff {
	margin-bottom: 20px;
	overflow: hidden;

	a {
		overflow: hidden;
		display: inline-block;
	}

	div.portrait {
		overflow: hidden;
		max-width: 300px;
		max-height: 300px;
	}

	a img {
		transition: transform .5s;
		-webkit-transition: transform .5s;
	}
	a:hover img {
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
	}
}

div.enviro-section {
	padding:20px;

	div {
	padding:10px;
	width: 100%;
	height: 100%;
	border-left: solid .2rem $brown-atlas;
	background-color: $brown-background;
	}

	p {margin: 0px;}
}


	 @media screen and (min-width: 1200px) {
		.homepage-header-small {
			display: none;
			visibility: hidden;
		}
	}

		 @media screen and (max-width: 640px) {
		.homepage-header-small {
			margin-top: 85px;
			div{
				img{
				max-width: 640px;
				min-width: 640px;
				width: 640px;
				min-height: 320px;
				}
			}
		}
	}

.homepage-header-background-color {
	width: 100%;
	height: 600px;
	z-index: -113;
	position: absolute;
}

.home-link {
	display: block;
	overflow: auto;
	width: 100%;
	height: 600px;
}


.link {
	margin-top: 85px;
	height: 420px;
	width: 1200px;
	position: absolute;
	top: 0;
	z-index: 1;

	@media screen and (max-width: 1200px) {
    	width: 100%;}

    @media screen and (max-width: 640px) {
    	height: 240px;
		margin-top: 70px;
    }
}

// Type and Font Styles


h1, {
	font-family: "Alike", serif !important;
	font-style: normal;
	font-weight: 400;
}


.big-link {
  font-size: 1.45rem;
  text-decoration: underline;
}

// Red Container

.red-container {
  background-color: $red-main;
  position: relative;
  color: $white;
  margin: 0 auto;
  padding: 3rem 0 2.5rem 0 !important;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
  z-index: 10;

a {
	color: $white;

	&:hover {
		color: $white-hover;
	}
}

  a.super-special-link {
	font-size: 1.2rem;
	padding: 10px;
	text-decoration: none;
	border: solid .02rem $white;
	transition: background-color .5s, color .5s, border .5s;

	&:hover {
		background-color: $red-dark-fifty;
		color: $red-dark;
		border: solid .02rem $red-dark;
	}

}

  h2 {
  border-bottom: .08rem solid rgba(255,255,255,.50);
  }

  .featured {
  	width: 100%;
  	height: auto;
  }

	    @media screen and (max-width: 640px) {

	    	.atlas-column {
	    		padding: 0px;
	    	}
    	}

}

.white-container {
	overflow: hidden;
	position: relative;
	background-color: white;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	z-index: 9;

}


.hm-image {
	overflow: hidden;
	transition: transform .5s;

	img {
		padding: 0px;
	}

	& img {
		transition: transform .5s;
	}

	&:hover img {
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
	}

}

div.featured-games {
	overflow: hidden;
	border-right: solid 5px rgba(0,0,0,0);
	border-left: solid 5px rgba(0,0,0,0);
	border-bottom: solid 10px rgba(0,0,0,0);
	@media screen and (max-width: 640px) {
		border-right: solid 0px rgba(0,0,0,0);
		border-left: solid 0px rgba(0,0,0,0);

	}
}

div.featured-games a img {transition: transform .3s, -webkit-transform .3s, -ms-transform .3s;}
div.featured-games:hover a img {
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
	}


.caption {
	text-align: center;
	width: 100%;
	font-size: $small-font-size;
}

small p.entry-published.date {
	font-size: .8rem !important;
}



//Background Image

/*Styles the background image*/

 .large-header-bg {
 	overflow: hidden !important;
 	min-height: 575px;
 	max-height: 576px;
 	z-index: -100;
 	position: absolute;
 	width: 100%;
	
	img {
		min-width: 2000px;
		max-width: 2000px;
	}

 	@media screen and (max-width: 640px) {
 	min-height: 345px;
 	max-height: 345px;

	img {
		padding-top: 100px;
		min-width: 1000px;
		max-width: 1000px;
		min-height: 400px;
		max-height: 400px;
	}

	}

	img.home {
		width: 100%;
		padding: 0;
		margin: 0;
	}
 }

 .header-darker {
 	background-color: rgba(0,0,0,.5);
 	width:100%;
 	height: 600px;
 	position: absolute;
 	z-index: 1;

 }

.product-background {
	overflow: hidden;
    height: 600px;
    margin-top: -100px;
    border-bottom: 1.5rem solid $red-main;

    @media screen and (max-width: 640px) {
    	height: 370px;}

}

.page-background-image {
	overflow: hidden;
	margin-top: -100px;
	margin-bottom: -80px;
    height: 370px;
	width: 100%;
	background: rgba(83,55,21,.75);


	.large-header-bg {
	//position: fixed;
	position: absolute;
	overflow: hidden;
	min-height: 370px;
 	max-height: 370px;
 	max-width: 100%;
	}

    @media screen and (max-width: 640px) {
    	height: 370px;}

}

.page-header-container {
	width: 100%;
	z-index: -10;
	padding-top: 85px;
	overflow: hidden !important;
	margin-bottom: -50px;
	height: 370px;
	border-bottom: 1.5rem solid #a82630;


	/* Small only */
	@media screen and (max-width: 640px) {
	max-height: 210px;
	border-bottom: 0rem solid #a82630;
	padding-top: 70px;
	margin-bottom: 0px;
	}
}

.page-header-container.post {
	margin-bottom: -200px;

		@media screen and (max-width: 640px) {
	max-height: 70px;
	padding-top: 0px;
	margin-bottom: 0px;
	}
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;


  @media screen and (min-width: 1024px) {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
	}
}

// Video styles
figure {
	position: relative;
  padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin-bottom: 20px;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		}
}

//Styles the background images
.left {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  height: auto;
  background-position: right;
  background-repeat: repeat-y;
  background-color: $brown-background;
  margin-top: 580px;
  &.page-smallheader {margin-top: 355px !important;}

}
.filler {
  -webkit-flex-grow: 100;
  -ms-flex-grow: 100;
  flex-grow: 100;
  max-width: 1200px;

}
.right {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  height: auto;
  background-position: left;
  background-repeat: repeat-y;
  background-color: $brown-background;
  margin-top: 580px;
  &.page-smallheader {margin-top: 355px !important;}

}

div.sub-page div.left, div.sub-page div.right {
	margin-top: 350px;
}


// Fixes flexbox breakpoint problem
@media screen and (max-width: 1024px) {
  .right, .left, .filler {
    display: none;
  }
}

.col-top {padding-top: 20px;}
.atlas-main-col {
	border-right: solid 2rem transparent;

@media screen and (max-width: 1024px) {
	border-right: 0;
}

}



table.product-info {
	font-size: 0.875rem;
	background-color: transparent !important;
	tbody {background-color: transparent !important;}
	tr{
		background-color: transparent !important;
		border-bottom: solid .1rem rgba(125,80,25,.4) !important;
		line-height: 1.2;
	th {
		min-width: 120px;
		text-align: left;
		padding: 2px 10px 4px 10px;
		display: flex;
		align-items: flex-start;
		}
	td {
		padding: 2px 10px 4px 10px;


	}
	}

	}


// Image Galleries

.image-gallery img {
	margin-bottom: 20px;
	transition: transform .3s, -webkit-transform .3s, -ms-transform .3s;
	border: solid .1rem rgba(0,0,0,.1);


	&:hover {
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
	}
}

//Sidebar

.prev-ed-line {
	margin-top: 10px;
	overflow: hidden;

	a:hover img {
		transform: scale(1.1);
	}

	a img {transition: transform .3s;}
}

aside {

	margin-bottom: 1.5rem;
	margin-top: 2rem;
	overflow: hidden;

.atlas-sidebar {
	font-size: 0.875rem;
	background-color: $brown-background;
	padding: 0 0 0 0;

		//Styles sidebar links

	li {
		.link-item::before {
			content: "\f0c1 ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
		.pdf-item::before {
			content: "\f1c1 ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
		.download-item::before {
			content: "\f019 ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
		.video-item:before {
			content: "\f03d ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
		.podcast-item:before {
			content: "\f2ce ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
		.users-item:before {
			content: "\f0c0 ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
		 a {
		 	padding-bottom: 3px;
			&:hover {
		 	border-bottom: 1px solid $brown-atlas;
			}
		 }
	}


	& > div {
		padding-left: 15px;
		padding-right: 15px;
	}

	a {
		color: $brown-atlas;}
	}

	h4 {
		background-color: $brown-atlas;
		color: $white;
		margin: 0 0 1.5rem 0;
		padding: 5px 10px;
		width: 100%;
		font-weight: 600;
	}

	h5 {
	color: $brown-atlas;
	border-bottom: solid 1px $brown-atlas;
	margin-bottom: .75rem;
	}

	ul {
	list-style-type: none !important;
	margin: 0 0 0 0;
	padding: 0rem .5rem;
	}

	@media screen and (max-width: 640px) {
	height: 100%;
	font-size: 0.85rem;
	background-color: $brown-background;
	padding: 0px;
	}
}


	.home-sidebar-item {
		padding-bottom: 1rem;

		a {
			margin-bottom: 10px;

		div.product-thumb {transition: transform .3s, -webkit-transform .3s, -ms-transform .3s, box-shadow .3s, -moz-box-shadow .3s, -webkit-box-shadow .3s;}
		div.product-thumb:hover{
			-ms-transform: scale(1.05) translateY(-2px);
			-webkit-transform: scale(1.05) translateY(-2px);
			transform: scale(1.05) translateY(-2px);
			-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
			box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
			}
		}
	}

	.home-sidebar-item a:nth-child(odd) {
		padding-right: 10px;
	}
	.home-sidebar-item a:last-child {
		padding-right: 0px;
	}

	.product-thumb {
		font-size: 78%;
		border: solid .09rem rgba(0,0,0,.1);
		border-radius: 5px;
		text-align: center;
		height: 100%;
		background-color: $white;
		color: $black-text;
		padding: 20px;
		display: flex;
		display: -webkit-flex;
		display: -ms-flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		justify-content: flex-start;
		-webkit-justify-content: flex-start;
		-ms-justify-content: flex-start;
		flex-wrap:wrap;
		-webkit-flex-wrap:wrap;
		-ms-flex-wrap:wrap;
		align-content: flex-start;
		-webkit-align-content: flex-start;
		-ms-align-content: flex-start;


		h3 {
			border-top: solid .02rem rgba(0,0,0,.2);
			margin: 0;
			padding: 5px 0 0 0;
			font-size: .8rem !important;
			font-weight: bold;
			width:100%;
			align-self: flex-start;
			-webkit-align-self: flex-start;
			-ms-align-self: flex-start;
			@media screen and (max-width: 640px) {
				font-size: .8rem !important;
				}
			}

		img {
			// padding-bottom: 20px;
			align-self: flex-start;
			-webkit-align-self: flex-start;
			-ms-align-self: flex-start;
			display: block;
			margin: 0 auto;


		}

		b {
			width: 100%;
			align-self: flex-end;
			-webkit-align-self: flex-end;
			-ms-align-self: flex-end;
			text-align: center;
		 }

		 form {width: 100%;}
	}
.download-zip-file:before {
	content: "\f019 ";
	-ms-transform: translate(-20px);
	-webkit-transform: translate(-20px);
	transform: translate(-20px);
	font-weight: 900;
	font-family: 'Font Awesome 5 Free';
}

.misc-thumb-container {
	& a {
		padding: 15px 0;

	div.misc-thumb{
		transition: transform .3s, -webkit-transform .3s, -ms-transform .3s, box-shadow .3s, -moz-box-shadow .3s, -webkit-box-shadow .3s;
	}
	}
}

a div.misc-thumb {


	&:hover {
		-ms-transform: scale(1.025) translateY(-2px);
		-webkit-transform: scale(1.025) translateY(-2px);
		transform: scale(1.025) translateY(-2px);
		-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
	}

	.download-print-file {
		color: $blue-link;
		font-size: 16.8px;
		width: 100%;
		text-align: center;

		&:before {
			content: "\f019 ";
			-ms-transform: translate(-20px);
			-webkit-transform: translate(-20px);
			transform: translate(-20px);
			font-weight: 900;
			font-family: 'Font Awesome 5 Free';
		}
	}

	&:hover .download-print-file {
		color: $blue-focus;
	}
}

			.link-thumb {
			font-size: 80%;
			border: solid .09rem rgba(0,0,0,.1);
			text-align: left;
			height: 100%;
			background-color: $brown-background;
			color: $black-text;
			padding: 20px;
			border-radius: 5px;
			display: flex;
			display: -webkit-flex;
			display: -ms-flex;
			flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			justify-content: flex-start;
			-webkit-justify-content: flex-start;
			-ms-justify-content: flex-start;
			flex-wrap:wrap;
			-webkit-flex-wrap:wrap;
			-ms-flex-wrap:wrap;
			align-content: flex-start;
			-webkit-align-content: flex-start;
			-ms-align-content: flex-start;
			transform: scale(1);
			-webkit-transition: transform .2s; /* Safari */
	    	transition: transform .2s;

			img {
				border: .1rem solid $background-darker;
				padding-bottom: 0px;
				margin-bottom: 20px;
			}

	    	h2 {
	    		font-size: 1.2rem;
	    	}

	    	ul{
	    		list-style-type: none;
	    		padding: 0;
	    		li {
	    			list-style-position: outside;
	    		}
	    	}
		}

		.thumb-margin {
			margin-bottom: 20px;
		}

			a.link-thumb:hover{
				transform: scale(1.05);
				color: $black-text;
			}

		@media screen and (max-width: 1024px) {
			.link-thumb{
			margin-bottom: 20px;
			}
		}
	.contact-box  {
		border: .1rem solid $red-dark-fifty;
		border-radius: 10px;
		padding: 20px;
		min-width: 300px;
	}

	@media screen and (max-width: 1024px) {
	.contact-box:first-of-type{
		margin-bottom: 20px;
	}
	}

	.contact-icon {
		float: left;
	}

	.contact-header {
		float: right;

		h3 {
			margin: 0px;
			padding: 0px;
		}

	}

	.contact-icon {margin-right: 15px;}

	.product-thumbs-container {

			a {
				overflow: hidden;
				padding: 15px;
				transform: scale(1);
				-webkit-transition: transform .2s; /* Safari */
	    		transition: transform .2s;

				&:hover {
					transform: scale(1.05);
					.product-thumb {
						border: solid .05rem rgba(0,0,0,.1);
						-webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
						-moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
						box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
					}
				}


			.product-thumb {
				font-size: 78%;
				text-align: center;
				height: 100%;
				color: $black-text;
				padding: 20px;
				max-width: 290px;
				border-radius: 5px;
				transition: box-shadow .2s, -moz-box-shadow .2s, -webkit-box-shadow .2s;

				@media screen and (max-width: 640px) {
					padding: 10px;
				}

			}
		}
	}

.buy-now > * {
	margin-bottom: .8rem;

	&:last-child {margin-bottom: 0em;}
}

 .sidebar-items > *:not(h4) {
 	padding-bottom: 1.5rem;
 }

.sidebar-product-purchase {

	padding: 1.5rem !important;

	h3, p {
		margin: 0;
	}

	}

.sm-download-pack {padding-bottom: 5px;}
.sm-btns, {margin-bottom: 20px;}

.sm-download-btn {
	margin: 3px;
	border: solid .1rem rgba(0,0,0,.2);
	border-radius: 20px;
	background-color: $brown-background;
	display:inline-block;
	overflow: auto;
	color: $brown-atlas;
	font-size: .8rem;
	padding: 8px 15px;
	transition: background-color .5s, color .5s;
	&:hover {
		background-color: $brown-dark-atlas;
		color: $white;
	}
}


.buy-button.product-page {
	max-width: 100%;
	font-size: 1rem;
}

	.buy-button {
		width: 100%;
		margin: 0 auto;

		form input[type="submit"] {
		width: 100%;
    	border: none;
		padding: .5rem;
		margin: .1rem;
		text-align: center;
		font-size: .8rem;
		cursor: pointer;
		background-color: #7D5019;
		-webkit-transition: transform .3s, color .3s; /* Safari */
    	transition: transform .3s, background-color .3s;
    	color: $white;

    	&:hover {
			-ms-transform: scale(1.05); /* IE 9 */
    		-webkit-transform: scale(1.05); /* Chrome, Safari, Opera */
    		transform: scale(1.05);
    		background-color: #533715;
		}

    	}
	}

.red-button {
	color: $brown-background;
	padding: 0px 15px;
	max-height: 40px;
	background-color: $red-main;

	&:hover {background-color: $red-atlas;}
}

.input-submit {
	color: $white !important;
    background-color: $brown-atlas;
    width: 200px;
    float: right;
    border: none;
    padding: .8rem;
    margin: .1rem;
    font-size: 1rem;
    text-align: center;
	cursor: pointer;
	-webkit-transition: transform .3s, color .3s; /* Safari */
   	 transition: transform .3s, background-color .3s;
		&:hover {
			-ms-transform: scale(1.05); /* IE 9 */
		    -webkit-transform: scale(1.05); /* Chrome, Safari, Opera */
		    transform: scale(1.05);
		    background-color: $brown-dark-atlas;
				}
}

.input-button {
	color: $white !important;
    background-color: $brown-atlas;
    width: 200px;
    float: left;
    border: none;
    padding: .8rem;
    margin: .1rem;
    font-size: 1rem;
    text-align: center;
	cursor: pointer;
	-webkit-transition: transform .3s, color .3s; /* Safari */
   	 transition: transform .3s, background-color .3s;
		&:hover {
			-ms-transform: scale(1.05); /* IE 9 */
		    -webkit-transform: scale(1.05); /* Chrome, Safari, Opera */
		    transform: scale(1.05);
		    background-color: $brown-dark-atlas;
				}
}
